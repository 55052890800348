<template>
  <Layout>
    <b-card>

      <b-table
        :fields="fields"
        striped
        hover
        :items="campaigns"
        :busy="loading"
        show-empty
        empty-text="No unpaid campaigns"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(updatedAt)="row">
          {{ moment(row.value).format("lll") }}
        </template>
        <template #cell(action)="row">
          <b-button @click="addPayment(row.item)">
            <i class="ri-add-line"></i>
            <span v-if="selectedCampaign === row.item._id">
              <span v-if="paying"> Paying... </span>
              <span v-else> Pay</span>
            </span>
            <span v-else> Pay </span>
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-pagination
      v-model="page"
      :total-rows="rows"
      :per-page="perPage"
      @change="updatePage"
    ></b-pagination>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { campaigns } from "@/config/api/campaigns";
import { getFormData } from "@/utils/createForm";
export default {
  created() {
    this.loadData();
  },
  data() {
    return {
      campaigns: [],
      rows: 0,
      page: 1,
      perPage: 10,
      paying: false,
      selectedCampaign: null,
      fields: [
        { key: "campaign_name", sortable: false },
        { key: "bonus_amount", sortable: false },
        { key: "no_of_days", sortable: false },
        { key: "sticker_type", sortable: false },
        { key: "flat_rate", sortable: false },
        { key: "no_of_drivers_required", sortable: false },
        { key: "plan_price", sortable: false },
        { key: "updatedAt", sortable: false },
        { key: "action", sortable: false },
      ],
      items: [
        { age: 40, first_name: "Dickerson", last_name: "Macdonald" },
        { age: 21, first_name: "Larsen", last_name: "Shaw" },
        { age: 89, first_name: "Geneva", last_name: "Wilson" },
        { age: 38, first_name: "Jami", last_name: "Carney" },
      ],
    };
  },
  components: {
    Layout,
  },
  methods: {
    loadData() {
      const api = campaigns.unpaidCampaigns;
      api.params = {
        page: this.page,
      };
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.campaigns = res.data.campaigns;
          this.rows = res.data.count;
          this.loading = false
        })
        .finally(() => {
          
          this.loading = false;
        });
    },
    updatePage(val) {
      console.log("CurrentPage", val);
      this.page = val;
      this.loadData();
    },
    addPayment(item) {
      this.selectedCampaign = item._id;
      this.$bvModal
        .msgBoxConfirm("Are you sure do you want to pay for this Campaign?", {
          title: "Payment approve",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = campaigns.addPaymentAdmin;
            api.data = getFormData({
              campaignId: item._id,
            });
            this.paying = true;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Payment added successfully!", {
                  title: "Payment",
                  variant: "success",
                  solid: true,
                });
                this.loadData();
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Payment rejected!, ${err.response.data.error}`,
                  {
                    title: "Payment",
                    variant: "danger",
                    solid: true,
                  }
                );
              })
              .finally(() => {
                this.paying = false;
              });
          }
        });
    },
  },
};
</script>
